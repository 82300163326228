import React, { FC, SVGProps } from 'react'

interface FrameLargeProps extends SVGProps<any> {
	image: string
	rotate: number
	isEmpty: boolean
}

const Frame730: FC<FrameLargeProps> = ({ image, rotate, isEmpty }) => {
	if (rotate == 0)
		return (
			<svg
				id="Layer_1"
				version="1.1"
				viewBox="0 0 890 589"
				width="891px"
				height="590px"
			>
				<rect
					stroke="#979797"
					strokeWidth="2px"
					x="1"
					y="1.4"
					width="888"
					height="586.2"
					rx="7.5"
					ry="7.5"
				/>
				<path
					fill="#f0efef"
					fillRule="evenodd"
					d="M45,521.6V41.6h800v480H45Z"
				/>
				<image
					width={800}
					height={480}
					xlinkHref={image}
					x={45}
					y={42}
					transform={!isEmpty ? 'rotate(180, 445, 282)' : undefined}
				/>
			</svg>
		)
	return (
		<svg viewBox="0 0 589 890" width="589px" height="890px">
			<g fill="none" fillRule="evenodd">
				<rect
					stroke="#979797"
					strokeWidth="2"
					fill="#000"
					x="1"
					y="1"
					width="586.218"
					height="888"
					rx="7.5"
				/>
				<path fill="#F0EFEF" d="M67 45h480v800H67z" />
			</g>
			<image
				width={800}
				height={480}
				xlinkHref={image}
				x={-118}
				y={228}
				transform="rotate(-90, 283, 444.18)"
			/>
		</svg>
	)
}

export default Frame730
