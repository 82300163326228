import React, { FC } from 'react'
import { useField } from 'formik'
import { SwitchProps } from '@mui/material/Switch'
import { AgitronSwitch } from '../CustomSwitch'
import { useCustomForm } from './hooks'
import { Typography } from '@mui/material'
import { TranslateMessage } from '@app/lib/lang'
import { Box } from '@mui/system'

export interface CustomFormAgitronSwitchProps extends SwitchProps {
	name: string
	onEnable?: () => void
	onDisable?: () => void
	label?: string
}

export const CustomFormAgitronSwitch: FC<CustomFormAgitronSwitchProps> = ({
	disabled: disabledFromProps,
	name,
	onChange,
	onEnable,
	onDisable,
	label,
	...otherProps
}) => {
	const { disabled } = useCustomForm()
	const [field, , helpers] = useField({
		name,
	})

	const onSwitchChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		if (!checked && onDisable) {
			onDisable()
			return
		}

		helpers.setValue(checked)

		if (onChange) {
			onChange(e, checked)
		}

		if (checked && onEnable) {
			onEnable()
		}
	}
	if (!label)
		return (
			<AgitronSwitch
				{...otherProps}
				disabled={disabled || disabledFromProps}
				onChange={onSwitchChange}
				checked={field.value || false}
			/>
		)

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				marginBottom: '20px',
				cursor: 'pointer',
			}}
			onClick={(e) => {
				if (disabled || disabledFromProps) return
				onSwitchChange(e as any, !field.value)
			}}
		>
			<Typography variant="h6">
				<TranslateMessage id={label} />
			</Typography>

			<AgitronSwitch
				{...otherProps}
				disabled={disabled || disabledFromProps}
				// onChange={onSwitchChange}
				checked={field.value || false}
			/>
		</Box>
	)
}
