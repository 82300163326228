import { FC, ReactNode } from 'react'
import { EPaperSizeType } from '../../constants/ePaperSizes'
import { frameLoader } from './frameLoader'
import Frame290 from './Frame290'
import Frame430 from './Frame430'
import FrameScale from './FrameScale'
import { Box } from '@mui/material'
import { bigNoTemplate } from './bigNoTemplate'
import { smallNoTemplate } from './smallNoTemplate'
import { getBase64ImageUrl } from '@app/lib/imageUtility'
import { DeviceType } from '@app/lib/graphql'
import Frame730 from './Frame730'
import { CustomButton } from '../buttons'
import { useFormikContext } from 'formik'
import { SmartLabelEditViewModel } from '@app/@types'
import Icon from '../Icon'
import { noTemplate730, noTemplate730Vertical } from './noTemplate730'

interface FramePreviewProps {
	image?: string | null
	loading: boolean
	paperSizeType: EPaperSizeType
	deviceType?: DeviceType | null
}

const getTemplate = (paperSizeType: EPaperSizeType, rotate: boolean) => {
	if (paperSizeType.startsWith('A290')) {
		return smallNoTemplate
	}
	if (paperSizeType.startsWith('A430')) {
		return bigNoTemplate
	}
	if (paperSizeType.startsWith('A730')) {
		return rotate ? noTemplate730Vertical : noTemplate730
	}

	return smallNoTemplate
}

export const FramePreview: FC<FramePreviewProps> = ({
	image,
	loading,
	paperSizeType,
	deviceType,
}) => {
	const is290 = paperSizeType.startsWith('A290')
	const is430 = paperSizeType.startsWith('A430')
	const is730 = paperSizeType.startsWith('A730')
	const formik = useFormikContext<SmartLabelEditViewModel>()

	const rotate = formik.values.rotated || false

	let frameImage: string
	let isEmpty = false
	if (loading) {
		frameImage = frameLoader
	} else if (!image) {
		isEmpty = true
		frameImage = getTemplate(paperSizeType, rotate)
	} else {
		frameImage = getBase64ImageUrl(image)
	}

	switch (true) {
		case deviceType === DeviceType.SmartScale:
			return (
				<CustomBox>
					<FrameScale image={frameImage} width="100%" />
				</CustomBox>
			)

		case is290:
			return (
				<CustomBox>
					<Frame290 image={frameImage} width="100%" />
				</CustomBox>
			)

		case is430:
			return (
				<CustomBox>
					<Frame430 image={frameImage} width="100%" />
				</CustomBox>
			)
		case is730:
			return (
				<CustomBox>
					{/* hidden */}
					<Box sx={{ width: '48px' }} />
					<Frame730
						image={frameImage}
						isEmpty={isEmpty}
						rotate={rotate ? -90 : 0}
						width="100%"
					/>
					<CustomButton
						onClick={() => {
							formik.setFieldValue(
								'rotated',
								!formik.values.rotated
							)
						}}
						style={{
							width: '48px',
							maxWidth: '48px',
							minWidth: '48px',
							padding: 0,
							margin: 0,
							right: 3,
							top: rotate ? 700 : 450,
						}}
					>
						<Icon icon="refresh" color="white" />
					</CustomButton>
				</CustomBox>
			)

		default:
			return (
				<CustomBox>
					<Frame290 image={frameImage} width="100%" />
				</CustomBox>
			)
	}
}

function CustomBox({ children }: { children: ReactNode }) {
	return (
		<Box
			sx={{
				position: 'relative',
				zIndex: 1,
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			{children}
		</Box>
	)
}
