import { useEffect, useMemo, useState } from 'react'
import { DocumentNode } from 'graphql'
import { useQuery } from '@apollo/client'
import { useField } from 'formik'
import CustomSelect, { CustomSelectProps } from '../CustomSelect'
import {
	BaseListQueryParameters,
	KeyValueObject,
	OffsetPaginationConnection,
	SelectOption,
} from '@app/@types'
import {
	getFirstPropertyFromObject,
	getDotNotationPropertyLast,
	getDotNotationPropertyValue,
	isArray,
} from '@app/lib/utils'
import { InputActionMeta } from 'react-select'
import { useDebounce } from 'use-debounce'
import { useCustomForm } from './hooks'
import { LIST_LIMIT } from '@app/constants'

export interface CustomFormAsyncSelectProps
	extends Omit<CustomSelectProps, 'selectedOption' | 'options'> {
	name: string
	gqlQuery: DocumentNode
	gqlVariables?: KeyValueObject
	searchParameter?: string
	initialValue?: any
	filterResults?: (item: any) => boolean
	disableOptionsFilter?: (item: any) => any
	onChange?: (selectedOption?: SelectOption, selectedItem?: any) => void
	searchParameterTransform?: (searchQuery: string) => any
	allOption?: SelectOption
	allValues?: boolean
}

export function CustomFormAsyncSelect({
	disabled: disabledFromProps,
	name,
	gqlQuery,
	gqlVariables,
	filterResults,
	onChange,
	onInputChange,
	multiple = false,
	labelKey = 'name',
	initialValue,
	labelTransform,
	searchParameter,
	searchParameterTransform,
	valueKey = 'id',
	allOption,
	allValues = true,
	disableOptionsFilter,
	...otherProps
}: CustomFormAsyncSelectProps) {
	const [searchLabel, setSearchLabel] = useState<string>()
	const { disabled } = useCustomForm()
	const [debouncedSearchLabel] = useDebounce(searchLabel, 300)
	const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([])
	const [field, , helpers] = useField({
		name,
	})

	let variables: BaseListQueryParameters = {
		limit: LIST_LIMIT,
		offset: 0,
	}

	if (gqlVariables) {
		variables = {
			...variables,
			...gqlVariables,
		}
	}

	if (debouncedSearchLabel) {
		if (searchParameterTransform) {
			variables.where = searchParameterTransform(debouncedSearchLabel)
		} else {
			const parameterToFilter = getDotNotationPropertyLast(
				searchParameter || labelKey
			)

			variables.where = {
				...variables.where,
				[parameterToFilter]: debouncedSearchLabel,
			}
		}
	}

	const { data } = useQuery(gqlQuery, {
		variables,
	})

	const firstProperty =
		getFirstPropertyFromObject<OffsetPaginationConnection>(data)

	const getValue = (item: any) => {
		let value = item

		if (valueKey) {
			value = getDotNotationPropertyValue(item, valueKey)
		}

		return value
	}

	const getSelectItem = (item: any): SelectOption => {
		let label = item

		if (labelTransform) {
			label = labelTransform(item)
		} else if (labelKey) {
			label = getDotNotationPropertyValue(item, labelKey)
		}

		if (allValues) {
			return {
				...item,
				label,
				value: getValue(item),
			}
		}

		return {
			label,
			value: getValue(item),
		}
	}

	const filterItem = filterResults || ((item: any) => true)

	const nodes = firstProperty?.nodes || []
	const options = useMemo<SelectOption[]>(() => {
		let newOptions = nodes.filter(filterItem).map(getSelectItem)
		if (allOption) newOptions.unshift(allOption)

		if (firstProperty?.totalCount > LIST_LIMIT) {
			newOptions.push({
				label: 'Search for more...',
				isDisabled: true,
				value: 'SRCHFRMRSRCHFRMR',
			})
		}
		if (disableOptionsFilter) {
			return newOptions.map((item) => {
				if (disableOptionsFilter(item)) {
					return {
						...item,
						isDisabled: true,
					}
				}
				return item
			})
		}

		return newOptions
	}, [data])

	useEffect(() => {
		let foundOptions: SelectOption[] = []

		if (multiple) {
			if (
				initialValue &&
				(!isArray(initialValue) || (initialValue as any[]).length)
			) {
				if (isArray(initialValue)) {
					foundOptions = initialValue.map(getSelectItem)
				} else {
					foundOptions = [getSelectItem(initialValue)]
				}
			} else {
				foundOptions = ((field.value as any[]) || []).map((value) => {
					let foundOption =
						options.find((q) => q.value === value) ||
						selectedOptions.find((q) => q.value === value)

					if (foundOption) {
						return foundOption
					}

					if (initialValue && isArray(initialValue)) {
						const foundInitialValue = (initialValue as any[]).find(
							(q) => getValue(q) === field.value
						)

						if (foundInitialValue) {
							return getSelectItem(foundInitialValue)
						}
					}

					return {
						value,
						label: value,
					}
				})
			}
		} else {
			let foundOption =
				options.find((q) => q.value === field.value) ||
				selectedOptions.find((q) => q.value === field.value)

			if (
				!foundOption &&
				initialValue &&
				getValue(initialValue) === field.value
			) {
				foundOption = getSelectItem(initialValue)
			}

			if (foundOption) {
				// SO THAT IT DOESNT SET ITSELF TO SEARCH FOR MORE WHEN CLEARING
				if ('value' in foundOption) {
					if (foundOption.value === undefined) {
						setSelectedOptions(foundOptions)
						return
					}
				}

				foundOptions = [foundOption]
			}
		}

		setSelectedOptions(foundOptions)
	}, [field.value, options])

	// useEffect(() => {
	// 	if (initialValue) setSelectedOptions([getSelectItem(initialValue)])
	// }, [initialValue, options])

	const onSelectInputChange = (
		input: string,
		actionMeta: InputActionMeta
	) => {
		setSearchLabel(input)

		if (onInputChange) {
			onInputChange(input, actionMeta)
		}
	}

	const onSelectCreate = (inputValue: string) => {
		const createdOption: SelectOption = {
			label: inputValue,
			value: inputValue,
		}

		setSelectedOptions([...selectedOptions, createdOption])

		if (multiple) {
			onSelectChange([...selectedOptions, createdOption], {})
		} else {
			onSelectChange(createdOption, {})
		}
	}

	const onSelectChange = (
		selectedOption: SelectOption | SelectOption[],
		actionMeta: any
	) => {
		if (multiple) {
			helpers.setValue(
				((selectedOption || []) as SelectOption[]).map((q) => q.value)
			)
		} else {
			helpers.setValue((selectedOption as SelectOption)?.value)

			if (onChange) {
				onChange(
					selectedOption as SelectOption,
					selectedOption
						? nodes.find(
								(q) =>
									getDotNotationPropertyValue(q, valueKey) ===
									(selectedOption as SelectOption).value
						  )
						: undefined
				)
			}
		}
	}

	return (
		<CustomSelect
			{...otherProps}
			placeholder={
				firstProperty?.nodes === undefined
					? ''
					: disabled || disabledFromProps
					? 'Not selected'
					: 'shared.typeToSearch'
			}
			disabled={disabled || disabledFromProps}
			multiple={multiple}
			options={options}
			onChange={onSelectChange}
			onCreate={onSelectCreate}
			onInputChange={onSelectInputChange}
			selected={multiple ? selectedOptions : selectedOptions[0]}
		/>
	)
}
